"use strict";

$(document).ready(function () {
  // Mobile Navbar Open
  $(".mobile-navbar-btn").on("click", function () {
    let headerNavbar = $(".header-navbar");
    headerNavbar.toggleClass("d-none");
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.add("overflow-hidden");
  });
  // Mobile Navbar Close
  $(".mobile-navbar-close-icon").on("click", function () {
    let headerNavbar = $(".header-navbar");
    headerNavbar.toggleClass("d-none");
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("overflow-hidden");
  });
  // Navigation Class
  let page = "/" + window.location.pathname.split("/")[1];
  $(".nav-item").each(function () {
    if ($(this).attr("href") == page || $(this).attr("href") == "")
      $(this).addClass("active");
  });
  // MapSelect
  let mapSelect = $(".map-maker-select");
  $(mapSelect).on("click", function (e) {
    e.preventDefault();
    
    $(".map-maker-select").each(function () {
      $(this).removeClass("active");
    });
    $(this).addClass("active");
    let selectedOffice = $(this).attr("data-target");
    $(".map-maker").each(function () {
      $(this).removeClass("d-block");
      $(this).addClass("d-none");
      $("." + selectedOffice).removeClass("d-none");
      $("." + selectedOffice).addClass("d-block");
    });
  });
  // Send Message
  $("form").on('submit', function(event) {
    event.preventDefault()
    
    $('.contact-submit-button').attr('disabled', 'disabled')
    
    let name = $('#contactFormName').val()
    let company = $('#contactFormCompany').val()
    let email = $('#contactFormEmail').val()
    let message = $('#contactFormMessage').val()
    let recaptchaResponse = grecaptcha.getResponse();
    let URL = $('.base-url-input').attr("data-baseurl");
    if(!$.trim(name)) {
      $('.contactNameGroup').addClass('error')
    }
    if(!$.trim(company)) {
      $('.contactCompanyGroup').addClass('error')
    }
    if(!$.trim(email)) {
      $('.contactEmailGroup').addClass('error')
    }
    if(!$.trim(message)) {
      $('.contactMessageGroup').addClass('error')
    }
    let data = {
      firstname: name,
      company: company,
      email,
      message,
      'g-recaptcha-response':recaptchaResponse
    }
    clear()
    window.location = 'mailto:' + data.email + '?subject=Contact Mail' + '&body=' +  data.message;
  $('.contact-submit-button').removeAttr('disabled')
    
  })
   // Clear Contact Form
   function clear() {
    $('#contactFormName').val('');
    $('#contactFormCompany').val('');
    $('#contactFormEmail').val('');
    $('#contactFormMessage').val('');
  }
});
